﻿export class ConnectivityHandler {
    // https://stackoverflow.com/questions/44756154/progressive-web-app-how-to-detect-and-handle-when-connection-is-up-again

    private _dotNetObject: DotNet.DotNetObject;

    constructor(dotNetObject: DotNet.DotNetObject) {
        this._dotNetObject = dotNetObject;
        window.addEventListener('online', this.handleConnection);
        window.addEventListener('offline', this.handleConnection);
    }

    private handleConnection = (): void => {
        if (navigator.onLine) {
            ConnectivityHandler.isReachable(ConnectivityHandler.serverUrl).then((isOnline: boolean) => {
                if (isOnline) {
                    // handle online status
                    this._dotNetObject.invokeMethod('ConnectionStatusChanged', 'online');
                    console.log('Connection status changed to: Online');
                    ConnectivityHandler.connectionStatusChanged(true);
                } else {
                    this._dotNetObject.invokeMethod('ConnectionStatusChanged', 'no connectivity');
                    console.log('Connection status changed to: No connectivity');
                    ConnectivityHandler.connectionStatusChanged(false);
                }
            });
        } else {
            // handle offline status
            this._dotNetObject.invokeMethod('ConnectionStatusChanged', 'offline');
            console.log('Connection status changed to: Offline');
            ConnectivityHandler.connectionStatusChanged(false);
        }
    }

    private static isReachable = (url): Promise<boolean | void> => {
        /**
         * Note: fetch() still "succeeds" for 404s on subdirectories,
         * which is ok when only testing for domain reachability.
         *
         * Example:
         *   https://google.com/noexist does not throw
         *   https://noexist.com/noexist does throw
         */
        return fetch(url, { method: 'HEAD', mode: 'no-cors' })
            .then(function (resp) {
                return resp && (resp.ok || resp.type === 'opaque');
            })
            .catch(function (err) {
                console.warn('[conn test failure]:', err);
            });
    }

    private static get serverUrl(): string {
        return window.location.origin;
        //return document.getElementById('serverUrl').value || window.location.origin;
    }

    private static connectionStatusChanged = (isOnline: boolean): void => {
        document.getElementById("blazor-error-connection").style.display = isOnline ? "none" : "initial";
    }
}
