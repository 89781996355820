﻿export class BlazorSessionStorage {
    public static get = (storageKey: string) => {
        return storageKey in sessionStorage ? JSON.parse(sessionStorage[storageKey]) : null;
    }

    public static set = (storageKey: string, value) =>
    {
        sessionStorage[storageKey] = JSON.stringify(value);
    }
}
