﻿export class BlazorLocalStorage {
    public static get = (storageKey: string) => {
        return storageKey in localStorage ? JSON.parse(localStorage[storageKey]) : null;
    }

    public static set = (storageKey: string, value) =>
    {
        localStorage[storageKey] = JSON.stringify(value);
    }
}
