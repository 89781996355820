﻿export class InstallationHandler {
    private isInstalledAsApp = true;
    private deferredInstallPrompt;
    private _dotNetObject: DotNet.DotNetObject;

    constructor(dotNetObject: DotNet.DotNetObject) {
        this._dotNetObject = dotNetObject;
        window.addEventListener('appinstalled', () => {
            this.isInstalledAsApp = true;
            this._dotNetObject.invokeMethod('SetIsInstalledAsApp', true);
        });

        window.addEventListener('beforeinstallprompt', (event: Event) => {
            this.isInstalledAsApp = false;
            this.deferredInstallPrompt = event;
            if (dotNetObject !== undefined) {
                dotNetObject.invokeMethod('SetIsInstalledAsApp', false)
            }
        });
    }

    public getDeviceInfo = (): string =>
        window.navigator.userAgent;

    public getIsInstalledAsApp = (): boolean =>
        this.isInstalledAsApp;

    public installApp = async () => {
        this.deferredInstallPrompt.prompt();
        // Wait for the user to respond to the prompt
        const { outcome } = await this.deferredInstallPrompt.userChoice;
        // Optionally, send analytics event with outcome of user choice
        //console.log(`User response to the install prompt: ${outcome}`);
        // We've used the prompt, and can't use it again, throw it away
        this.deferredInstallPrompt = null;
    }
}
