﻿export class AuthSettings {

    private static authSettingsKey = "Microsoft.AspNetCore.Components.WebAssembly.Authentication.CachedAuthSettings";
    authority: string;
    client_id: string;
    post_logout_redirect_uri: string;
    redirect_uri: string;
    response_type: string;
    scope: string;

    private constructor() {
    }

    public static build = async (configUrl: string): Promise<AuthSettings> => {
        if (!sessionStorage[AuthSettings.authSettingsKey]) {
            console.log(`AuthSettings in session storage key '${AuthSettings.authSettingsKey}' not found.`);
            const configurationResponse: Response = await fetch(configUrl);
            const authSettings: AuthSettings = await configurationResponse.json();
            console.log('authSettings', authSettings);
            sessionStorage[AuthSettings.authSettingsKey] = JSON.stringify(authSettings);
            return authSettings;
        }
        const authSettings: AuthSettings | null = sessionStorage ? JSON.parse(sessionStorage[AuthSettings.authSettingsKey]) : null;
        if (!authSettings) {
            throw new Error(`AuthSettings in session storage key '${AuthSettings.authSettingsKey}' could not be parsed.`);
        }
        return authSettings;
    }
}

export class OIDC {

    public static getAuthSettings = async (configUrl: string): Promise<AuthSettings> => {
        const authSettings: AuthSettings = await AuthSettings.build(configUrl);
        return authSettings;
    }

    public static getDiscovery = async (discoveryUrl: string): Promise<JSON> => {
        const discoveryResponse: Response = await fetch(discoveryUrl);
        const discovery: JSON = await discoveryResponse.json();
        return discovery;
    }

    private static getOidcUserKey = (authSettings: AuthSettings): string => {
        return `oidc.user:${authSettings.authority}:${authSettings.client_id}`;
    }

    public static getOidcUser = async (configUrl: string): Promise<JSON> => {
        const authSettings: AuthSettings = await AuthSettings.build(configUrl);
        const oidcUserKey: string = OIDC.getOidcUserKey(authSettings);
        if (!sessionStorage[oidcUserKey]) {
            return JSON.parse('{ }');
        }
        const oidcUser: JSON | null = sessionStorage ? JSON.parse(sessionStorage[oidcUserKey]) : null
        if (!oidcUser) {
            throw new Error(`OIDC user in session storage key '${oidcUserKey}' not found.`);
        }
        return oidcUser;
    }

    public static updateOidcUser = async (configUrl: string, newOidcUser: JSON | null) => {
        const authSettings: AuthSettings = await AuthSettings.build(configUrl);
        const oidcUserKey = OIDC.getOidcUserKey(authSettings);
        if (!newOidcUser) {
            delete sessionStorage[oidcUserKey];
            return;
        }
        if (!sessionStorage[oidcUserKey]) {
            sessionStorage[oidcUserKey] = JSON.stringify(newOidcUser);
            return;
        }
        const oldOidcUser: JSON | null = sessionStorage ? JSON.parse(sessionStorage[oidcUserKey]) : null;
        oldOidcUser['access_token'] = newOidcUser['access_token'];
        oldOidcUser['id_token'] = newOidcUser['id_token'];
        oldOidcUser['session_state'] = newOidcUser['session_state'];
        oldOidcUser['profile'] = newOidcUser['profile'];

        sessionStorage[oidcUserKey] = JSON.stringify(oldOidcUser);
    }

}
