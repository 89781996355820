﻿import IMask from 'imask';

import { BlazorLocalStorage } from './components/blazor-local-storage';
import { BlazorSessionStorage } from './components/blazor-session-storage';
import { ConnectivityHandler } from './components/connectivity-handler';
import { InstallationHandler } from './components/installation-handler';
import { NotificationsHandler } from './components/notifications-handler';
import { OIDC } from './components/oidc';

window['BlazorLocalStorage'] = BlazorLocalStorage;
window['BlazorSessionStorage'] = BlazorSessionStorage;
window['NotificationsHandler'] = NotificationsHandler;
window['OIDC'] = OIDC;


// Function called from WebAssembly
window['initializeDotNetObject'] = (dotNetObject: DotNet.DotNetObject) => {
    window['ConnectivityHandler'] = new ConnectivityHandler(dotNetObject);
    window['InstallationHandler'] = new InstallationHandler(dotNetObject);
    console.log("DOM dotNetObject initialized.");
}

window['setPageTitle'] = (title: string) => {
    document.title = title;
}

window['getElementHeight'] = (element: Element): number => {
    if (!element)
        return null;

    const offsetHeight: number | null = element['offsetHeight'];
    if (!offsetHeight || offsetHeight > (window.innerHeight - element.getBoundingClientRect().top)) {
        return Math.floor(window.innerHeight - element.getBoundingClientRect().top);
    }

    return Math.floor(element['offsetHeight']) ?? null;
}

window['getElementDistanceToBottom'] = (element: Element): number => {
    if (!element)
        return null;

    return Math.floor(window.innerHeight - element.getBoundingClientRect().y);
}

window['tableObserverInit'] = (tableId: string) => {
    if (!tableId)
        return null;

    const tableBodyElement: Element = document.getElementById(tableId)?.querySelector("tbody");
    if (!tableBodyElement)
        return null;

    const observer: MutationObserver = new MutationObserver(window['maskInputs']);
    observer.observe(tableBodyElement, { childList: true });
};

window['maskInputs'] = () => {
    const inputMaskParameterName = "data-inputmask";
    const elements: NodeListOf<Element> = document.querySelectorAll("[" + inputMaskParameterName + "]");

    elements.forEach((element: HTMLElement) => {
        if (element instanceof HTMLInputElement) {
            if (element.getAttribute(inputMaskParameterName).toLowerCase() === "Number".toLowerCase()) {
                new IMask.InputMask(<HTMLInputElement>element, {
                    mask: Number,
                    thousandsSeparator: " ",
                    min: element.getAttribute("min") ?? 0,
                    max: element.getAttribute("max") ?? 999,
                    scale: element.getAttribute("scale") ?? 0,
                    padFractionalZeros: element.getAttribute("padFractionalZeros") ?? true
                });
                return;
            }
            new IMask.InputMask(<HTMLInputElement>element, { mask: element.getAttribute(inputMaskParameterName) });
            return;
        }
        const masked = IMask.createMask({ mask: element.getAttribute(inputMaskParameterName) });
        element.innerHTML = masked.resolve(element.innerHTML);
    });
};

window['saveAsFile'] = (filename: string, bytesBase64: string) => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(new Blob([bytesBase64]));
    link.type = "text/plain";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

window['downloadFileFromStream'] = async (fileName, contentStreamReference) => {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer]);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}

// Close Navbar when clicked outside
document.addEventListener('click', function (event: MouseEvent) {
    const navbarToggler: HTMLElement = document.querySelector('.navbar .navbar-toggler');
    const isClickedWithinNavbar: boolean = (event.target as HTMLElement).closest('.navbar') !== null;
    const isClickedWithinNavbarDropdown: boolean = (event.target as HTMLElement).closest('.nav-item.dropdown') !== null;
    const isNavbarExpanded: boolean = navbarToggler?.getAttribute('aria-expanded') === 'true';
    if (isClickedWithinNavbar && !isClickedWithinNavbarDropdown && isNavbarExpanded) {
        navbarCollapse();
    }
});

const navbarCollapse = () => {
    const navbarToggler: HTMLElement = document.querySelector('.navbar .navbar-toggler');
    if (navbarToggler?.getAttribute('aria-expanded') === 'true') {
        navbarToggler.click();
    }
}

window['navbarCollapse'] = navbarCollapse();

const textAreaAutosizeEvent = (event: Event) => {
    const textArea: HTMLTextAreaElement | null = event.srcElement as HTMLTextAreaElement;
    if (!textArea)
        return;

    setTimeout(() => {
        textAreaAutosize(textArea);
    }, 0);
}

const textAreaAutosize = (textArea: HTMLTextAreaElement) => {
    textArea.style.cssText = 'height:auto; padding:0';
    // for box-sizing other than "content-box" use:
    // el.style.cssText = '-moz-box-sizing:content-box';
    textArea.style.cssText = 'height:' + (textArea.scrollHeight * 1.1 + 32) + 'px';
}

window['textAreaObserverInit'] = () => {
    const textAreas: NodeListOf<HTMLTextAreaElement> | null = document.querySelectorAll('textarea.autoresize');
    if (!textAreas)
        return;

    textAreas.forEach(textArea => {
        textArea.addEventListener('keydown', textAreaAutosizeEvent);
        textAreaAutosize(textArea);
    });
};

window['localizePaging'] = () => {
    const culture: string = window['blazorCulture'].get();
    if (culture !== 'sk-SK') {
        return;
    };
    const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a.page-link');
    links.forEach(link => {
        switch (link.textContent) {
            case 'First':
                link.textContent = 'Prvá';
                break;
            case 'Last':
                link.textContent = 'Posledná';
                break;
            case 'Previous':
                link.textContent = 'Predošlá';
                break;
            case 'Next':
                link.textContent = 'Nasledujúca';
                break;
        }
    });
};

window['scrollIntoView'] = (element: Element) => {
    if (!element)
        return;

    element.scrollIntoView();
}

window['blazorCulture'] = {
    get: () => window.localStorage['BlazorCulture'],
    set: (value) => window.localStorage['BlazorCulture'] = value
};
