﻿export class Subscription {
    constructor(pushSubscription: PushSubscription) {
        this.url = pushSubscription.endpoint;
        this.p256dh = Subscription.arrayBufferToBase64(pushSubscription.getKey('p256dh'));
        this.auth = Subscription.arrayBufferToBase64(pushSubscription.getKey('auth'));
    };

    private static arrayBufferToBase64(buffer: ArrayBuffer | null) {
        // https://stackoverflow.com/a/9458996
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    url: string;
    p256dh: string;
    auth: string;
}

export class NotificationsHandler {

    public static isNotificationsActive = async (): Promise<boolean> => {
        const worker: ServiceWorkerRegistration = await navigator.serviceWorker.getRegistration();
        const existingSubscription: PushSubscription | null = await worker.pushManager.getSubscription();
        return existingSubscription !== null;
    }

    public static requestSubscription = async (): Promise<Subscription | null> => {
        const worker: ServiceWorkerRegistration = await navigator.serviceWorker.getRegistration();
        const existingSubscription: PushSubscription | null = await worker.pushManager.getSubscription();
        if (!existingSubscription) {
            const newSubscription: PushSubscription = await NotificationsHandler.subscribe(worker);
            if (newSubscription) {
                return new Subscription(newSubscription);
            }
        }
    }

    private static subscribe = async (worker: ServiceWorkerRegistration): Promise<PushSubscription> => {
        try {
            const applicationServerPublicKey = (document.getElementById('pwaNotificationsPublicKey') as HTMLInputElement).value;
            return await worker.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerPublicKey
            });
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                return null;
            }
            throw error;
        }
    }
}
